.text-bold-20 {
  position: relative;
  line-height: 150%;
}
.cross-icon {
  width: 12px;
  position: relative;
  height: 12px;
}
.close-popup-btn {
  cursor: pointer;
  border: none;
  padding: 5px;
  background-color: transparent;
  align-self: stretch;
  width: 30px;
  border-radius: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.close-popup-btn:hover {
  background-color: #003cff;
}
.upgrade-plan-box {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.popup-header {
  align-self: stretch;
  border-bottom: 1px solid rgba(20, 20, 43, 0.2);
  box-sizing: border-box;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.text-regular-18 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1em;
  line-height: 20px;
  text-transform: uppercase;
}
.affordable-pricing-plans {
  align-self: stretch;
  position: relative;
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 32px;
  font-weight: 600;
  color: #0d0d0d;
}
.pricing-text-div {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  gap: 8px;
}
.frame-item {
  width: 26px;
  position: relative;
  border-radius: 50%;
  background-color: #003cff;
  height: 26px;
  z-index: 0;
}
.frame-inner {
  width: 26px;
  position: absolute;
  margin: 0 !important;
  top: 26px;
  left: 26px;
  border-radius: 50%;
  background-color: rgba(0, 60, 255, 0.3);
  height: 26px;
  transform: rotate(-180deg);
  transform-origin: 0 0;
  z-index: 1;
}
.ellipse-group {
  width: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.plan-icon {
  border-radius: 8px;
  background-color: rgba(0, 60, 255, 0.3);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 7px;
}
.plan-icon-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 20px;
}
.week-trial {
  font-size: 18px;
  font-weight: 600;
}
.paragraph {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 20px;
  color: rgba(13, 13, 13, 0.85);
}
.price {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 100%;
  font-weight: 600;
}
.month {
  width: 67px;
  position: relative;
  font-size: 16px;
  line-height: 22px;
  color: rgba(13, 13, 13, 0.7);
  display: flex;
  align-items: flex-end;
  height: 28px;
  flex-shrink: 0;
}
.price-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
}
.upgrade-plan-btn {
  align-self: stretch;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  color: #000;
}

@media screen and (min-width: 430px) {
  .upgrade-plan-btn {
    margin-top: 20px;
  }  
}

.standard-plan {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(20, 20, 43, 0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 20px;
  gap: 16px;
  min-width: 250px;
  max-width: 350px;
}
.exclusive-invite-only-access1 {
  font-size: 16px;
  font-family: var(--font-family);
}
.valid-for-3 {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--font-family);
}
.exclusive-invite-only-access {
  margin: 0;
}
.paragraph2 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 20.5px;
  margin: 20px 0;
  color: rgba(13, 13, 13, 0.85);
}
.subscrptiontext {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
}
.code-input {
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px;
  min-width: 200px;
  outline-color: #003cff;
}
.upgrade-btn {
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  min-width: 120px;
  color: #000;
}

.upgrade-plan-btn:hover,
.upgrade-btn:hover {
  background-color:  rgba(0, 60, 255, 0.2);
}

.invitation-btns-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  color: rgba(0, 0, 0, 0.2);
}
.pricing-plans {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0px 20px;
  box-sizing: border-box;
  gap: 18px;
  text-align: left;
  font-size: 16px;
  color: #0d0d0d;
}
.pricing-box {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0px;
  gap: 30px;
  text-align: center;
  font-size: 18px;
  color: #003cff;
  overflow: scroll;
}
/* Hide scrollbar for WebKit browsers */
.pricing-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Internet Explorer and Edge */
.pricing-box {
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Firefox */
.pricing-box {
  scrollbar-width: none; /* Firefox */
}

.pricing-popup {
  position: relative;
  background: linear-gradient(
      180deg,
      rgba(1, 60, 255, 0.3),
      rgba(255, 255, 255, 0)
    ),
    #f7f9ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1000px;
  max-height: 100%;
  text-align: left;
  font-size: 20px;
  color: #000;
  font-family: var(--font-family);
  border-radius: 8px;
}
