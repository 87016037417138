.dropdown-wrapper2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 0px 12px 8px;
}

/* .dropdown-wrapper2:active .dropdown,
.dropdown-wrapper2:hover .dropdown {
  display: flex;
} */

/*  */

.sidebar {
  align-self: stretch;
  width: 300px;
  background-color: #fff;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.tab-text {
  position: relative;
  line-height: 150%;
}
.header-tab {
  border-radius: 4px;
  background-color: rgba(0, 60, 255, 0.1);
  height: 26px;
  display: inline-flex; /* Changed to inline-flex */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  box-sizing: border-box;
}
.header-tab-active {
  background-color: #003cff;
  color: #fff;
}

.header-tabs-frame {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 1px;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--font-family);
  font-size: var(--mobile-font-size);
}

.header-tabs-frame-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 640px;
  gap: 8px;
  overflow-x: auto;
}

.header-tabs-frame-2::-webkit-scrollbar {
  display: none;
}

.profile-icon4 {
  width: 37px;
  position: relative;
  height: 37px;
  overflow: hidden;
  flex-shrink: 0;
}
.profile-icon-frame {
  width: 38px;
  border-radius: 27px;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
.session-page-header {
  align-self: stretch;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: var(--mobile-font-size);
}
.user-prompt {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 18px;
  font-weight: var(--font-weight-user-prompt);
}
.ai-response {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 1.4;
  font-family: var(--font-family);
}
.like-btn-div {
  width: 20px;
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.like-btn-div:hover {
  background-color: rgba(0, 60, 255, 0.2);
}
/* Tooltip container */
.like-btn-div .tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px 8px;
  position: absolute;
  bottom: 140%; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-family: var(--font-family);
  font-size: var(--mobile-font-size);
}

/* Tooltip arrow */
.like-btn-div .tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show the tooltip when hovering over the button */
.like-btn-div:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
/*  */

.divider-icon {
  width: 0px;
  position: relative;
  height: 12px;
  object-fit: contain;
}
.like-btns-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.ai-response-frame {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 1000px;
  text-align: left;
  color: rgba(30, 41, 59, 0.85);
  overflow: visible;
}
.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 1000px;
}
.chat-interface {
  width: 100%;
  flex: 1;
  border-radius: 12px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0px 20px;
  padding-top: 8px;
  box-sizing: border-box;
  max-width: 700px;
  text-align: right;
  color: #1e293b;
  white-space: pre-wrap;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chat-interface::-webkit-scrollbar {
  display: none;
}

.suggestion-text {
  position: relative;
  letter-spacing: -0.01em;
}
.suggestion-btn {
  border-radius: 4px;
  background-color: rgba(0, 60, 255, 0.05);
  border: 1px solid rgba(0, 60, 255, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
}
.suggestions-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 2px;
  box-sizing: border-box;
  width: 100%;
  max-width: 700px;
  gap: 8px;
  white-space: nowrap;
  overflow-x: auto;
}
.suggestions-frame::-webkit-scrollbar {
  display: none;
}
.input-box1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}
.vector-icon {
  width: 20px;
  position: relative;
  height: 20px;
  object-fit: contain;
}
.send-icon {
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 10px rgba(86, 97, 246, 0.2);
  border-radius: 27px;
  background-color: #003cff;
  border: 1px solid rgba(218, 221, 255, 0.5);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.send-icon-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 16px;
}
.type-box1 {
  width: 100%;
  height: 45px;
  box-shadow: 0px 0px 26.7px rgba(0, 60, 255, 0.1);
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(0, 60, 255, 0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  max-width: 700px;
  text-align: left;
  font-size: var(--mobile-font-size);
  color: #999;
  font-family: var(--font-family);
}
.prompt-frame {
  align-self: stretch;
  box-shadow: 0px -9px 47.2px #f7f9ff;
  background-color: #f7f9ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  gap: 8px;
  text-align: center;
  overflow: hidden;
  color: rgba(13, 13, 13, 0.85);
}
.session-page-main {
  align-self: stretch;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
@media screen and (min-width: 700px) {
  .session-page-main {
    border: 1px solid #003cff4d;
    border-radius: 4px;
  }
}

.session-page {
  width: 100%;
  position: relative;
  height: 100dvh;
  background: linear-gradient(
      to bottom,
      rgba(150, 170, 234, 0.3) 0%,
      rgba(255, 255, 255, 0) 20%
    ),
    #f7f9ff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--mobile-font-size);
  color: #525252;
  font-family: var(--font-family);
}
