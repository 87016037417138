


:root {
  --font-family: inherit;
  --line-height: 1.4;
  --mobile-font-size: 15px;
  --mobile-font-size-heading: 18px;
  --font-weight-button: 500;
  --font-weight-special-heading: 600;
  --font-weight-user-prompt: 580;
}

*,
:after,
:before {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
}

img,
svg {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

input,
select,
textarea {
  margin: 0;
  padding: 0;
  font: inherit;
}

button {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
}

body {
  font-family: sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
title {
  color: #231f20;
}

a {
  color: unset;
}

:focus {
  outline-color: #418a2f;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #4e473a;
  height: 50px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #4e473a;
  height: 16px !important;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-corner {
  background-color: transparent;
}
