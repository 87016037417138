/* Dropdown */

.dropdown-wrapper {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  border-radius: 27px;
  align-items: center;
  padding: 16px; 
  justify-content: center;
  box-sizing: border-box;
  display: flex; 
  flex-direction: column;
  width: fit-content; 
  height: fit-content; 
}
.dropdown-wrapper:active .dropdown,
.dropdown-wrapper:hover .dropdown {
  display: flex;
}

/* logon Screen */

.tp-connect-response {
  color: #B00020;
}

.teamskip-logo-icon {
  width: 246px;
  position: relative;
  height: 60px;
}
.go {
  position: relative;
}
.go-button {
  box-shadow: 0px 0px 10px rgba(86, 97, 246, 0.2);
  border-radius: 4px;
  background-color: #999;
  border: 1px solid rgba(218, 221, 255, 0.5);
  box-sizing: border-box;
  height: 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 10px 10px;
  color: #fff;
  font-family: var(--font-family);
  margin: 10px 0px 10px 20px;
}
.go-button-active {
  background-color: #003cff;
}

.input-frame {
  width: 100%;
  box-shadow: 0px 0px 26.7px rgba(0, 60, 255, 0.1);
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(0, 60, 255, 0.2);
  box-sizing: border-box;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px 0px 12px;
  max-width: 700px;
  text-align: center;
  color: #999;
  font-family: var(--font-family);
}
.reconnect-area {
  cursor: pointer;
  flex: 1;
}

.input {
  border: none;
  outline: none;
  flex: 1;
  height: 100%;
}

.backend-response {
  flex: 1;
  position: relative;
}
.text-frame {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  font-size: 16px;
}
.icon {
  width: 48px;
  position: relative;
  border-radius: 42px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.tab-text {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.tab {
  border-radius: 4px;
  background-color: rgba(0, 60, 255, 0.05);
  border: 1px solid rgba(82, 82, 82, 0.2);
  box-sizing: border-box;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  gap: 12px;
  flex: 1;
  min-width: 160px;
  max-width: 220px;
  perspective: 1000px; /* Define the perspective for the 3D effect */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.tab:hover {
  background-color: rgba(0, 60, 255, 0.2); /* Slightly darker color on hover */
}

.tab-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.tab:hover .tab-inner {
  transform: rotateY(180deg);
}

.tab-front,
.tab-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.tab-back {
  transform: rotateY(180deg);
}

.tabs-frame {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  max-width: 700px;
  font-size: var(--mobile-font-size);
  gap: 8px;
}
.main-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 40px;
  flex-grow: 1;
}
.logon-screen {
  width: 100%;
  position: relative;
  background: linear-gradient(
      to bottom,
      rgba(150, 170, 234, 0.3) 0%,
      rgba(255, 255, 255, 0) 20%
    ),
    #f7f9ff;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--mobile-font-size);
  color: #525252;
  font-family: var(--font-family);
}

/* Text Animation */

.animated-text {
  align-self: stretch;
  position: relative;
}

@keyframes animateText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappearText {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animated-text span {
  opacity: 0;
  animation: animateText 1s forwards;
}

.animated-text span:nth-child(1) {
  animation-delay: 0s;
}

.animated-text span:nth-child(2) {
  animation-delay: 0.3s;
}

.animated-text span:nth-child(3) {
  animation-delay: 0.6s;
}

.animated-text span:nth-child(4) {
  animation-delay: 0.9s;
}

.animated-text span:nth-child(5) {
  animation-delay: 1.4s;
}

.animated-text span:nth-child(6) {
  animation-delay: 1.5s;
}

.animated-text span:nth-child(7) {
  animation-delay: 1.8s;
}

.animated-text span:nth-child(8) {
  animation-delay: 2.1s;
}

.animated-text span:nth-child(9) {
  animation-delay: 2.4s;
}

.animated-text span:nth-child(10) {
  animation-delay: 2.7s;
}

/* Landing Page Screen */

.skip-logo-icon {
  width: 148px;
  position: relative;
  height: 36px;
}
.profile-icon {
  width: 37px;
  height: 37px;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown-wrapper2 {
  cursor: pointer;
  border: none;
  padding: 16px;
  background-color: transparent;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 20px;
}
.loading-animation {
  width: 215px;
  height: 215px;
}
.loading-text {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.loading-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.tab-blurred-icon {
  width: 48px;
  position: relative;
  border-radius: 42px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.tab-description {
  align-self: stretch;
  position: relative;
  font-size: var(--mobile-font-size);
  line-height: 120%;
  font-family: var(--font-family);
}
.tab-text-frame2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.tab-blurred {
  width: 220px;
  border-radius: 4px;
  background-color: rgba(168, 168, 168, 0.05);
  height: 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
  gap: 12px;
  min-width: 160px;
  max-width: 220px;
  min-height: 150px;
}
.tabs-blurred-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  font-size: var(--mobile-font-size);
  color: #bebebe;
  gap: 8px;
}
.main-loading-frame {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 23px 20px;
  gap: 30px;
}
.landing-page {
  width: 100%;
  position: relative;
  backdrop-filter: blur(4px);
  background: linear-gradient(
      to bottom,
      rgba(150, 170, 234, 0.3) 0%,
      rgba(255, 255, 255, 0) 20%
    ),
    #f7f9ff;
  min-height: 100dvh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--mobile-font-size);
  color: #525252;
  font-family: var(--font-family);
}

@media screen and (max-width: 960px) {
  .main-loading-frame {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .main-loading-frame {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

/* Landing Page Ready Frame */

.text-32 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: var(--font-weight-special-heading);
}
.text-41 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 120%;
  font-family: var(--font-family);
}
.tab-text-frame3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.tab-ready {
  width: 220px;
  flex: 1;
  border-radius: 4px;
  background-color: rgba(0, 60, 255, 0.05);
  height: 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
  gap: 12px;
  min-width: 160px;
  max-width: 220px;
  cursor: pointer;
}
.tab-ready:hover {
  background-color: rgba(0, 60, 255, 0.2);
}
.tabs-ready-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  padding: 0px 20px;
  gap: 8px;
}
.main-loading-ready-frame {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* TP Connect Frame */

.tp-logo-icon {
  width: 157px;
  position: relative;
  height: 73px;
  object-fit: cover;
}
.tp-logo-frame {
  align-self: stretch;
  border-radius: 4px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0),
      rgba(1, 60, 255, 0.2)
    ),
    rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 60, 255, 0.2);
  box-sizing: border-box;
  height: 121px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.btn-text {
  position: relative;
}
.connect-icon {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.connect-btn {
  align-self: stretch;
  box-shadow: 0px 0px 10px rgba(86, 97, 246, 0.2);
  border-radius: 4px;
  background-color: #003cff;
  border: 1px solid rgba(218, 221, 255, 0.5);
  box-sizing: border-box;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 8px 10px 10px;
  gap: 10px;
  cursor: pointer;
}
.text-a {
  position: relative;
  line-height: 150%;
}
.register-now-btn {
  position: relative;
  line-height: 150%;
  color: #003cff;
  cursor: pointer;
}
.register-btn-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  text-align: center;
  font-size: var(--mobile-font-size);
  color: rgba(0, 0, 0, 0.5);
  font-family: var(--font-family);
}
.buttons-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.tp-buttons-frame {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  max-width: 500px;
}

.info-accordions {
  align-self: stretch;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #525252;
  gap: 12px;
}
.tp-connect-main-frame {
  margin: 80px 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px 20px;
  box-sizing: border-box;
  gap: 30px;
  text-align: left;
  font-size: var(--mobile-font-size);
  color: #fff;
  font-family: var(--font-family);
}
