.why-training-peaks {
  flex: 1;
  position: relative;
  line-height: 100%;
}

.icbaseline-minus-icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.accordion-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-family: Gilroy-SemiBold;
  cursor: pointer;
}
.accordion-des {
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: Gilroy-RegularItalic;
}
.accordion-des.hidden {
  display: none;
}
.card {
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 16px;
  box-sizing: border-box;
  max-width: 500px;
}
.card.active {
  border: 1px solid rgba(0, 60, 255, 0.2);
}
