.pic-frame {
  width: 46px;
  position: relative;
  border-radius: 33px;
  background-color: rgba(0, 60, 255, 0.6);
  height: 46px;
  overflow: hidden;
  flex-shrink: 0;
}
.name,
.plan {
  align-self: stretch;
  position: relative;
}
.plan {
  font-size: 14px;
  font-family: Gilroy-Regular;
  white-space: pre-wrap;
}
.header-frame1,
.intro-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.header-frame1 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.upgrade-text {
  position: relative;
  font-size: 16px;
  font-family: Gilroy-Regular;
  color: #000;
  text-align: left;
}
.upgrade-button {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  background-color: transparent;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.button-frame:hover,
.upgrade-button:hover {
  background-color: rgba(0, 60, 255, 0.2);
}
.iconoutlinelayer {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.lead-icon-s {
  width: 26px;
  border-radius: 44px;
  background-color: rgba(0, 60, 255, 0.2);
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.view-profile {
  position: relative;
  font-size: 16px;
  font-family: Gilroy-Regular;
  color: rgba(13, 13, 13, 0.85);
  text-align: center;
}
.button-frame,
.view-profile-button {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.button-frame {
  flex: 1;
  align-items: center;
  padding: 0px 8px;
}
.view-profile-button {
  cursor: pointer;
  border: 0;
  padding: 4px 8px;
  background-color: transparent;
  align-items: flex-start;
  gap: 8px;
}
.divider-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0;
  flex-shrink: 0;
}
.options-frame {
  width: 292px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.privacy-policy {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: 12px;
  font-family: Gilroy-Regular;
  color: rgba(13, 13, 13, 0.5);
  text-align: center;
  display: inline-block;
}
.privacy-policy:hover {
  color: #003cff;
}
.divider-vertical {
  width: 1px;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  height: 15px;
}
.footer-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
.dropdown {
  margin: 0 !important;
  position: absolute;
  width: 320px;
  top: 55px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 60, 255, 0.2);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 60, 255, 0.1);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  gap: 16px !important;
  z-index: 2;
  text-align: left;
  font-size: 18px;
  color: #000;
  font-family: Gilroy-Medium;
}

/*  */

.dropdown2 {
  margin: 0 !important;
  position: absolute;
  width: 320px;
  top: 55px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 60, 255, 0.2);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 60, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  gap: 16px !important;
  z-index: 2;
  text-align: left;
  font-size: 18px;
  color: #000;
  font-family: Gilroy-Medium;
}